
import api from '@/api/co.api'
import app from '@/api/co.app'
import lib from '@/api/co.lib'
import activity from '@/api/co.activity'

export default {
  data () {
    return {
      actParams: null,
      actItems: [],
      actPagination: {
        current: 1,
        length: 1,
        totalVisible: 7
      },
      actLoading: false,
      navPart: {}
    }
  },
  methods: {
    onActivity (ev) {
      const item = ev.item
      this.toActivity(this.navPart, item)
    },
    toActivity (part, item) {
      const params = {
        activityId: item.activityId,
        type: item.type,
      }
      const name = api.page.noticeDetail.name
      lib.cookie.set(name, JSON.stringify(params))
      this.$router.push({ name })
    },
    changePagination (number) {
      const params = this.actParams
      const data = params.data
      const limit = parseInt(data.limit, 10) || 9
      const index = parseInt(number, 10) || 1

      this.actPagination.current = index
      data.offset = (index - 1) * limit
      params.reload = true
      api.http.getItems(params)
    },
    initActivityParams ({
      name = '',
      type = '',
      limit = '10'
    }) {
      const me = this
      const executing = function () {
        me.actLoading = true
      }

      const executed = function (res) {
        me.actLoading = false
        if (res.status) {
          const data = me.actParams.data
          const pagination = me.actPagination
          const limit = parseInt(data.limit) || 10
          pagination.length = Math.ceil(res.total / limit)
        }
      }

      this.actParams = activity.main.getParams({
        siteId: app.init.siteId,
        type,
        status: 'publish',
        limit,
        caches: this.actItems,
        executing,
        executed
      })
    },
    loadActivitys (reload = true) {
      this.actParams.reload = reload
      api.http.getItems(this.actParams)
    }
  }
}
