<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
      :breadcrumbs="banApp.crumbs"
    />
    <land-section
      id="psqcenter"
      space="48"
    >
      <v-container>
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            md="10"
          >
            <div class="d-flex flex-column">
              <land-gallery
                :items="actItems"
                per-columns="2"
                ratio="2"
                back-color="grey lighten-5"
                @click="onActivity"
              />
              <div class="d-flex flex-row mt-4">
                <v-pagination
                  v-model="actPagination.current"
                  :length="actPagination.length"
                  :total-visible="actPagination.totalVisible"
                  @input="changePagination"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
    <land-speed-dial
      :activator="speedDial.activator"
      :items="speedDial.items"
      @click="onSpeedDial"
    />
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import lib from '@/api/co.lib'

  import mixHomeActivityBanner from '@/pages/mixins/home/mix.home.activity.banner'
  import mixActivityList from '@/pages/mixins/activity/mix.activity.list'
  import mixSpeedDial from '@/pages/mixins/mix.speeddial'

  export default {
    metaInfo () {
      return {
        title: this.pageTitle
      }
    },
    mixins: [
      mixHomeActivityBanner,
      mixActivityList,
      mixSpeedDial
    ],
    data () {
      return {
        pageTitle: '活动报名'
      }
    },
    created () {
      const name = api.page.activityList.name
      const nav = JSON.parse(lib.cookie.get(name))

      Object.assign(this.navPart, nav)
      this.pageTitle = nav.title

      this.setCrumbSelf(nav)
      this.setCrumbs(null)

      this.speedDial.items.push(
        this.dialHome,
        this.dialComplaints,
        this.dialMaintain,
        this.dialReport
      )

      this.initBannerParams({
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()

      this.initActivityParams({
        name: nav.name,
        type: nav.type
      })
      this.loadActivitys()
    }
  }
</script>
